import React from 'react';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <main className="w-screen h-screen bg-gray-200 flex items-center justify-center text-center p-2">
    <SEO title="404: Not found" />
    <div className="text-gray-700">
      <h1 className="text-2xl font-bold text-gray-500">404: NOT FOUND</h1>
      <p className="mt-2">
        You just hit a route that doesn&#39;t exist... the sadness.
      </p>
    </div>
  </main>
);

export default NotFoundPage;
